@use "sass:map";
@import "styles/variables.scss";

.container {
   border-radius: 8px;
   border: 1px solid #f0f0f0;
   background: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;
   height: 100%;
   align-items: flex-start;
   @include media-down(md) {
      padding: 0px;
      border: none;
   }
}

.main_title {
   font-size: 24px;
   font-weight: 600;
   line-height: 32px;
   display: flex;
   align-items: center;
   @include media-down(md) {
      font-size: 18px;
      line-height: 24px;
   }
}

.title-wrapper {
   display: flex;
   gap: 8px;
}

.title {
   font-size: 18px;
   font-weight: 500;
   line-height: 24px;
   text-align: center;
}

.title1 {
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   text-align: center;
   color: #697f90;
   margin-top: 8px;

   @include media-down(md) {
      text-align: start;
   }
}

.item {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 12px;

   @include media-down(md) {
      flex-direction: row;
   }
}

.btn-submit {
   color: #fff;
   font-size: 20px;
   font-weight: 500;
   line-height: 28px;
   white-space: nowrap;
   overflow: hidden;
   margin-top: 16px;
   height: 46px;
   width: 100%;
   max-width: 256px;
   border: unset;
   display: flex;
   align-items: center;
   justify-content: center;

   border-radius: var(--Border-Radius-borderRadiusLG, 8px);
   background: var(--Orange-orange-500, #ff6347);
   transition: opacity 0.3s ease;
   cursor: pointer;

   @include media-down(md) {
      max-width: 100%;
   }

   &:active {
      opacity: 0.6;
   }

   .tag {
      color: #252a2b;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 4px 8px;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      background: var(--yellow-yellow-500, #ffd630);
      margin-left: 8px;
      position: relative;

      @include media-down(xl) {
      }

      span {
         position: relative;
         z-index: 1;
      }

      &::after {
         content: "";
         position: absolute;
         left: 21px;
         top: 0px;
         transform: skew(131deg, 180deg);
         width: 23px;
         height: 24px;
         background-color: #ffeaad;
         z-index: 0;
      }
   }

   &[disabled] {
      opacity: 0.6;
   }
}

.description {
   display: flex;
   flex-direction: column;
   align-items: center;
   @include media-down(md) {
      align-items: flex-start;
   }
}

.block {
   padding: 24px;
   background: #ffffff;
   border-radius: 8px;
   @include media-down(md) {
      padding: 16px 12px;
      border-radius: 0;
   }
}

.block-sub {
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
   @include media-down(md) {
      margin-bottom: 0;
   }
}

.spacet-partner {
   margin-top: 12px;
   @include media-down(md) {
      // margin-left: -12px;
   }
   // .partner-item {
   //    padding: 20px;
   //    border-radius: 8px;
   //    border: 1px solid #f0f0f0;
   //    background: #fff;
   // }
}
