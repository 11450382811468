.faq-container {
   .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
   }

   .title1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
   }

   .accordion-body {
   }

   .faq-item {
      color: #252a2b !important;
      font-weight: 500;
      background: #fff !important;
      &::after {
         color: #252a2b !important;
         background-image: var(--bs-accordion-btn-icon) !important;
      }
      .collapsed {
         &:focus,
         &:focus-visible {
            box-shadow: none;
         }
      }
   }
}

.accordion-body {
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   text-align: left;
   color: #697f90;
   padding: 12px;
}
