@use "sass:map";
@import "styles/variables.scss";

.container {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   max-width: 500px;
   width: 100%;
   background: white;
   padding: 16px;
   border-radius: 10px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

   @include media-down(md) {
      max-width: 350px;
   }
}

.title {
   color: #252a2b;
   text-align: center;
   font-size: 18px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   margin-bottom: 16px;
}

.message {
   color: #666;
   line-height: 1.5;
   margin-bottom: 16px;
}

.phoneNumber {
   color: #ff424e;
}

.divider {
   width: 100%;
   height: 1px;
   background-color: #e8e8e8;
   margin-bottom: 16px;
}

.button {
   white-space: nowrap;
   overflow: hidden;
   height: 46px;
   width: 100%;
   border: none;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: var(--Border-Radius-borderRadiusLG, 8px);
   background: #e2e4eb;
   transition: opacity 0.3s ease;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px;
   color: #252a2b;

   &:active {
      opacity: 0.6;
   }

   &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
   }
}
