@use "sass:map";
@import "styles//variables.scss";

.form-wrap-vertical {
   border-radius: 12px;
   padding: 12px;
   background: #fff;

   @include media-down(md) {
      margin-top: 0;
      padding-bottom: 0;
      padding: 10px;
      padding-bottom: 24px;
   }

   .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 16px;
      padding-bottom: 0;
      color: #697f90;
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
   }
}

.form-vertical {
   display: grid;
   gap: 16px;
   grid-template-columns: 1fr;

   .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
   }

   input {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      height: 48px;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      border: 1px solid var(--gray-gray-400, #e2e4eb);
      background: var(--Background-White, #fff);
      width: 100%;
      outline: none;

      &::placeholder {
         color: #8294a2;
      }

      &:focus {
         outline: 2px solid #007bff;
      }
   }

   .btn-submit-vertical,
   .btn-submit-vertical-2 {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      white-space: nowrap;
      overflow: hidden;
      height: 46px;
      width: 100%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      background: var(--Orange-orange-500, #ff6347);
      transition: opacity 0.3s ease;

      @include media-down(lg) {
         font-size: 14px;
      }

      &:active {
         opacity: 0.6;
      }

      &[disabled] {
         opacity: 0.6;
         cursor: not-allowed;
      }

      .tag {
         color: #252a2b;
         font-size: 14px;
         font-weight: 500;
         line-height: 20px;
         padding: 4px 8px;
         border-radius: var(--Border-Radius-borderRadiusLG, 8px);
         background: var(--yellow-yellow-500, #ffd630);
         margin-left: 8px;
         position: relative;

         span {
            position: relative;
            z-index: 1;
         }

         &::after {
            content: "";
            position: absolute;
            left: 21px;
            top: 0;
            transform: skew(131deg, 180deg);
            width: 23px;
            height: 24px;
            background-color: #ffeaad;
            z-index: 0;
         }
      }
   }

   .btn-submit {
      margin-top: 25px;
   }

   .btn-submit-vertical {
      margin-top: 0px;
   }

   .btn-submit-vertical-2 {
      margin-top: 300px;
   }

   .text-error {
      // position: absolute;
      color: #ff6347;
      font-size: 14px;
   }

   .form-item {
      position: relative;
   }

   .form-item.error input {
      border: 1px solid #ff6347;
   }
}

.asterisk {
   color: #ff4d4f;
   position: absolute;
   margin-top: 12px;
   margin-left: 5px;
   z-index: 1;
}

.phone-input-container {
   display: flex;
   align-items: center;
   width: 100%;
   border-radius: 8px;
   border: 1px solid #e2e4eb;
   height: 48px;

   .country-code {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      cursor: pointer;
      border-radius: 6px;
      margin-left: 8px;

      &:hover {
         background-color: #f5f5f5;
      }

      svg {
         color: #666;
         transition: transform 0.2s ease;
      }

      .country-dropdown {
         position: absolute;
         top: calc(100% + 8px);
         left: -2px;
         background: white;
         border-radius: 8px;
         box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
         min-width: 240px;
         z-index: 1000;
         padding: 8px 0;
         max-height: 200px;
         overflow-x: hidden;
         overflow-y: auto;
         border: 1px solid #e2e4eb;

         &::-webkit-scrollbar {
            width: 6px;
         }

         &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
         }

         &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 3px;
         }

         .country-option {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
               background-color: #f5f5f5;
            }

            span {
               color: #333;
               font-size: 14px;
               white-space: nowrap;
            }
         }
      }

      .country-flag {
         width: 20px;
         height: 20px;
         border-radius: 50%;
         object-fit: cover;
      }

      span {
         color: #333;
         font-size: 14px;
      }
   }

   > div:first-child {
      padding-left: 0px;
   }

   input:focus {
      outline: none;
   }

   &:has(input:focus) {
      outline: 2px solid #007bff;
      padding: 0;
   }

   .separator-line {
      width: 1px;
      height: 44px;
      background-color: #e2e4eb;
      margin: 0;
      padding: 0;
   }
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .form-wrap-vertical {
      padding: 0px 10px 10px 10px;
      margin-top: 12px;
      .title {
         font-size: 16px;
         line-height: 19px;
      }
      .form {
         grid-template-columns: repeat(1, minmax(0, 1fr));
         gap: 24px;

         .label {
            font-size: 14px;
            line-height: 19px;
         }

         .btn-submit {
            font-size: 16px;
            margin-top: 10px;
         }
      }
   }
}
