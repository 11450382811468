@use "sass:map";
@import "styles//variables.scss";

// Base styles for form wraps
.form-wrap,
.form-wrap-vertical,
.form-wrap-vertical-2 {
   border-radius: 12px;
   padding: 24px;

   @include media-down(md) {
      margin-top: 0;
      padding-bottom: 0;
   }

   .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 16px;
      padding-bottom: 0;
   }
}

// Specific styles for .form-wrap
.form-wrap {
   margin-top: 20px;
   background: #f5f5f5;

   @include media-down(md) {
      background: #fff;
   }
}

.form-wrap-vertical,
.form-wrap-vertical-2 {
   background: #fff;

   @include media-down(md) {
      padding: 10px;
      padding-bottom: 24px;
   }

   .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
   }

   .title-2 {
      color: #252a2b;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
   }
}

/* Global Form Styles */
.form,
.form-vertical {
   display: grid;
   gap: 16px;

   .label {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
   }

   input {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      height: 48px;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      border: 1px solid var(--gray-gray-400, #e2e4eb);
      background: var(--Background-White, #fff);

      &::placeholder {
         color: #8294a2;
      }
   }

   .btn-submit,
   .btn-submit-vertical {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      white-space: nowrap;
      overflow: hidden;
      height: 46px;
      width: 100%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      background: var(--Orange-orange-500, #ff6347);
      transition: opacity 0.3s ease;

      &:active {
         opacity: 0.6;
      }

      &[disabled] {
         opacity: 0.6;
         cursor: not-allowed;
      }

      .tag {
         color: #252a2b;
         font-size: 14px;
         font-weight: 500;
         line-height: 20px;
         padding: 4px 8px;
         border-radius: var(--Border-Radius-borderRadiusLG, 8px);
         background: var(--yellow-yellow-500, #ffd630);
         margin-left: 8px;
         position: relative;

         span {
            position: relative;
            z-index: 1;
         }

         &::after {
            content: "";
            position: absolute;
            left: 21px;
            top: 0;
            transform: skew(131deg, 180deg);
            width: 23px;
            height: 24px;
            background-color: #ffeaad;
            z-index: 0;
         }
      }
   }

   .btn-submit {
      margin-top: 25px;
   }

   .btn-submit-vertical {
      margin-top: 0px;
   }

   .text-error {
      position: absolute;
      color: #ff6347;
      font-size: 14px;
   }

   .form-item.error input {
      border: 1px solid #ff6347;
   }
}

/* Specific Styles for Horizontal Form */
.form {
   grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* Specific Styles for Vertical Form */
.form-vertical {
   grid-template-columns: 1fr;
}

@media (max-width: map.get($grid-breakpoints-max-width, "md")) {
   .form-wrap,
   .form-wrap-vertical {
      padding: 0px;
      margin-top: 0px;
      .title {
         font-size: 16px;
         line-height: 19px;
      }
      .form {
         grid-template-columns: repeat(1, minmax(0, 1fr));
         gap: 8px;

         .label {
            font-size: 14px;
            line-height: 19px;
         }

         .btn-submit {
            font-size: 16px;
            margin-top: 10px;
         }
      }
   }

   .form-wrap-vertical-2 {
      padding: 0px 10px 30px 10px;
      margin-top: 0px;
      .title {
         font-size: 16px;
         line-height: 19px;
      }
      .form {
         grid-template-columns: repeat(1, minmax(0, 1fr));
         gap: 24px;

         .label {
            font-size: 14px;
            line-height: 19px;
         }

         .btn-submit {
            font-size: 16px;
            margin-top: 10px;
         }
      }
   }
}
