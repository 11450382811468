.btn-submit {
   color: #fff;
   font-size: 20px;
   font-weight: 500;
   line-height: 28px;
   white-space: nowrap;
   overflow: hidden;
   height: 46px;
   width: 100%;
   border: none;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: var(--Border-Radius-borderRadiusLG, 8px);
   background: var(--Orange-orange-500, #ff6347);
   transition: opacity 0.3s ease;

   &:active {
      opacity: 0.6;
   }

   &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
   }

   @media (max-width: 768px) {
      max-width: unset;
   }
}

.tag {
   color: #252a2b;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   padding: 4px 8px;
   border-radius: var(--Border-Radius-borderRadiusLG, 8px);
   background: var(--yellow-yellow-500, #ffd630);
   margin-left: 8px;
   position: relative;

   span {
      position: relative;
      z-index: 1;
   }

   &::after {
      content: "";
      position: absolute;
      left: 21px;
      top: 0;
      transform: skew(131deg, 180deg);
      width: 23px;
      height: 24px;
      background-color: #ffeaad;
      z-index: 0;
   }
}
